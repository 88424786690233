<template>
	<b-card>
		<form @submit.prevent="saveItem">
			<div class="form-group">
				<label>FAQ Category</label>
				<select 
					class="form-control"
					v-model="formFaq.category_uuid"
				>
					<option value="">-- Pilih FAQ Category --</option>
					<option 
						v-for="category in faqsCategory" 
						:key="category.uuid"
						:value="category.uuid"
					>
						{{ category.name }}
					</option>
				</select>
			</div>
			<div class="form-group">
				<label>Judul</label>
				<input 
					type="text"
					class="form-control"
					v-model="formFaq.title"
				>
			</div>
			<div class="form-group">
				<label>Konten</label>
				<textarea
					class="form-control"
					v-model="formFaq.content"
				/>
			</div>
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
			>
				Save
			</button>
			&nbsp;
			&nbsp;
			<a
				@click="handleBackButton()"
				variant="secondary"
				class="btn waves-effect waves-float waves-light btn-secondary"
			>
				Cancel
			</a>
		</form>
	</b-card>
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BCol,
	BFormCheckbox
} from 'bootstrap-vue'
import { successNotification, errorNotification } from '@/auth/utils'
export default {
	components: {
		BCard,
		BCol,
		BFormGroup,
		BFormRadio,
		BFormCheckbox,
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			formFaq:{
				title: '',
				content: '',
				category_uuid:''
			},
			faqsCategory: [],
			category_uuid: ''
		}
	},

	mounted() {
		this.loadItem()
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		async loadItem() {
			let uri = window.location.href.split('?');
			this.formFaq.category_uuid = uri[1]
		},
		getDataCategory() {
			this.$http.get('admin/faq-categories')
			.then(response => {
				this.faqsCategory = response.data.data.data
			})
		},
		saveItem() {
			this.$http.post('admin/faqs/', this.formFaq)
			.then(response => {
				successNotification(this, 'Success', 'Faq Content sukses dibuat!')
				this.$router.push({ name: 'faq-content' })
			})
		}
	},
	created() {
		this.getDataCategory()
	}
}
</script>

<style>

</style>